.nice-dates-navigation{
    color:#eab308 !important;
    font-weight: bolder;
}
.nice-dates-navigation_previous:hover:before, .nice-dates-navigation_next:hover:before {
    color:#eab308 !important;
}
.nice-dates-day:before {
    background-color:#eab308 !important;
    z-index: 1;
}
.nice-dates-day:after {
    border: 2px solid #eab308 !important;
}
