
.selecte-dea .css-13cymwt-control{

    @apply !rounded-md border-gray-200 !h-[40px] dark:bg-transparent dark:border-gray-600 dark:text-white
}

.selecte-dea .css-1p3m7a8-multiValue {
    @apply bg-purple-900 !text-white rounded-full px-4
}
.selecte-dea .css-wsp0cs-MultiValueGeneric {
    @apply !text-white
}