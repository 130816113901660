/* CustomQuillStyles.css */
.ql-container {
  border: none !important;
}

.ql-editor {
  border: none !important;
  @apply dark:text-white text-black  max-h-[40vh] min-h-[24px];

}
.ql-toolbar {
  border: none !important;
  @apply dark:text-white rounded-t-xl;
}
.ql-toolbar.ql-snow {
 @apply bg-white dark:bg-darkTheme-700 dark:text-white;
}
