.suggestion p {
    @apply border-b-[1px] text-xxs md:text-sm  dark:border-gray-800 text-gray-700 dark:text-gray-300 py-2 px-4 cursor-pointer
}

.suggestion p:hover {
    @apply bg-gray-100 dark:bg-gray-800;
}

/*
last element in the list should not have a border bottom
*/

.suggestion p:last-child {
    @apply border-none
}


.suggestion-main::-webkit-scrollbar {
    @apply hidden
}

@keyframes incremental-height {
    0% {
        height: 0;
    }
    100% {
        height: 200px;
    }
}


.suggestion-main {
    animation: incremental-height 0.2s ease-in-out;
}