@keyframes bottom-to-top {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes top-top-bottom {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(100%);
    }

}


.bottom-to-top-animation {
  animation: bottom-to-top 0.2s ease-out;
}


