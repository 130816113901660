
.card {
    @apply rounded-lg shadow-sm bg-white dark:bg-gray-800 ;
}

.line {
    @apply h-[1px] my-2 bg-gray-300 rounded-full dark:bg-darkBgTertiary;
}


@keyframes ltr {
    0% {
        left: -100%;
    }
    100% {
        left: 0;
    }
}

@keyframes rtl {
    0% {
        left: 0%;
    }
    100% {
        left: -100%;
    }
}

.open-left-to-right-drawer {
    animation: ltr 2.5s ease-in-out;
}

.open-left-to-right-drawer.hidden {
    animation: ltr 1.1s ease-in-out;
}


.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.primary-button-color {
    @apply bg-purple-800  text-white hover:bg-purple-700 dark:bg-purple-500 dark:hover:bg-purple-400;
}

.primary-button-outline-color {
    @apply border-purple-900 hover:opacity-90 border-[1px] text-black dark:text-white hover:border-purple-800 dark:border-purple-500 dark:hover:border-purple-400;
}


.primary-text {
    @apply text-purple-800 dark:text-purple-500;
}


.flex-col-center {
    @apply flex items-center justify-center flex-col;
}

.flex-row-center {
    @apply flex items-center justify-center flex-row;
}


.bordered-primary-button {
    @apply border-purple-800 border-[1px]  text-purple-800 rounded-full  dark:text-purple-500 dark:border-purple-500;
}

