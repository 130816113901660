.spinner {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 3px solid #f3f3f3; /* Light grey */
    @apply border-t-[3px] border-t-purple-600 dark:border-t-brand-700;
    animation: rotate 1s linear infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}